<template>
  <div class="an-li" @click="showInfo">
<!--    {{item.id}}-->
    <div class="image zhuan-jia-image" style="width: 80px;">
      <van-image
          class="image-div"
          height="70px"
          width="70px"
          style="pointer-events:none;"
          round
          :src="item.image[0].url"
      />
    </div>
    <div class="info">

      <div class="name tal-flex tal-items-center tal-justify-between">
        <div class="zhuan-jia-name">
          {{ item.nickname ? item.nickname : '暂无数据' }}
        </div>
        <div class="">
          {{ _.split(_.get(item, 'address_name', []), ',', 2).join('-') }}
        </div>
      </div>
      <div class="van-ellipsis  van_position zhuan-jia-zhi-wei" v-if="item.zhi_wei">
        {{ item.zhi_wei ? item.zhi_wei : '暂无数据' }}
      </div>
      <div class="van-three-ellipsis g-name">
        {{ item.zheng_shu ? item.zheng_shu : '暂无数据' }}
      </div>
      <div class="text-right" style="padding-right: 7px">
        <van-button type="primary" size="mini" @click.stop="freeCounsel">免费咨询</van-button>
      </div>
    </div>

  </div>
</template>

<script>
import http from "../../../api/http";
import XEUtils from "xe-utils";
import {useRouter} from "vue-router";
import _ from 'lodash'

export default {
  name: 'SpecialList',
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup(props) {
    const router = useRouter()

    function showInfo() {
      let to = {name: 'PlatformSpecialistDetails', query: {id: props.item.id}}
      XEUtils.set(to, 'query.ran_id', XEUtils.uniqueId('page_'))
      router.push(to)
    }

    return {
      showInfo,
      _
    }
  },
  methods: {
    freeCounsel() {
      http.get('/portal/index/getKeFuUrl').then(da => {
        if (da.code === 1) location.href = da.data.content
      })
    }
  }
}
</script>


<style scoped lang="less">
@import "../../../assets/basis_css/style/var";

.an-li {
  display: flex;
  margin: 10px;
  padding: 8px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px #ecdddd;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-between;
  justify-content: space-around;
  align-items: flex-start;

  div {
    margin: 1px 0;
  }

  .name {
    //
    //font-weight: 700;
  }

  .van_position {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 3px;
  }

  .type {
    padding: 5px 0;
  }

  .van-three-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .g-name {
    font-size: 14px;
    //color: @gray-6;
  }

  .van_content {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .di-zhi {
    color: @gray-6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;

    .van_list_item {
      display: flex;
      align-items: center;
    }
  }

  .image {
    padding-top: 10px;
    width: 125px;
  }

  .info {
    flex: 1;
    padding: 0 0 0 15px;
    width: calc(100% - 125px);
  }
}

.zhuan-jia-name {
  font-weight: bold;
  font-size: 16px;

}

.zhuan-jia-zhi-wei {
  font-width: 100px;
}

.an-li-di-zhi {
  text-align: right;
  color: @gray-6;
}

</style>
