<template>
  <van-field
      :placeholder="placeholder"
      readonly
      :required="required"
      :rules="rules"
      is-link
      :label="label"
      v-model="value"
      @click="status = true"
  />
  <van-popup v-model:show="status" style="width: 100%;height: 300px;" position="bottom" teleport="body">
    <van-tree-select
        height="260px"
        v-model:active-id="activeIds"
        v-model:main-active-index="activeIndex"
        :items="shu_cha"
    />
    <van-button block type="primary" @click="submit">确定</van-button>
  </van-popup>

</template>

<script>
//二级分类选择
import {get} from "@/api/http";
import XEUtils from "xe-utils";


export default {
  name: "SelectErJiType",
  props: {
    required: Boolean,
    placeholder: String,
    rules: Array,
    url: String,
    label: String,
    inputType: { //radio checkbox
      type: String,
      default() {
        return 'checkbox'
      }
    },

    id: [String, Number], //自动填写
    name: [String, Number],//二级名称
    allName: [String, Number]//完整的名称 自动填写
  },
  data() {
    return {
      value: '',
      status: false,
      shu_cha: [],
      activeIds: this.inputType === 'checkbox' ? [] : '', //active-id 为数组格式时，可以选中多个右侧选项。
      activeIndex: 0
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    /**
     *
     * @param ids
     */
    setValue(ids) {
      ids = XEUtils.isArray(ids) ? ids : XEUtils.toString(ids).split(',')
      ids = XEUtils.map(ids, n => XEUtils.toInteger(n))
      ids = XEUtils.filter(ids, n => n)

      if (XEUtils.toString(ids) === XEUtils.toString(this.activeIds)) {
        return
      }

      if (this.inputType === 'checkbox') {

        this.activeIds = ids
      } else {
        this.activeIds = XEUtils.toInteger(ids.join(','))
      }

      this.submit()

    },
    submit() {
      let all_name = [] //获取完整的名称 ['一级/二级',……]
      //获取最后一级的的名称
      let last_name = []
      XEUtils.eachTree(this.shu_cha, n => {
        if (XEUtils.includes(this.getActiveIds, n.id)) {
          last_name.push(n.name)
          let parent = XEUtils.find(this.shu_cha, n2 =>{
            return  n2.id === n.parent_id
          })
          if (XEUtils.get(parent,'name','') != '') {
            all_name.push([parent.name, n.name].join('/'))
          }
        }
      }, {children: 'children'})
      console.log(3333333333,last_name)
      this.value = last_name.join('、')
      this.$emit('update:name', last_name.join(','))
      this.$emit('update:allName', all_name.join(','))
      this.$emit('update:id', this.getActiveIds.join(','))
      this.status = false
    },
    getData() {
      get(this.url).then(da => {
        this.shu_cha = XEUtils.mapTree(da.data, n => {
          n.id = XEUtils.toString(n.id)
          n.text = n.name
          return n
        })

        this.$watch('allName', this.jianTingAllName, {
          immediate: true
        })
        this.$watch('id', this.jian_ting_id, {
          immediate: true
        })
      })
    },
    jian_ting_id(newVal) {
      if (XEUtils.isUndefined(newVal)) return
      if (XEUtils.isUndefined(this.jian_ting_id_fang_dou)) {
        this.jian_ting_id_fang_dou = XEUtils.debounce(val => {
          val = XEUtils.toString(val).split(',')
          let xuan_ze = []
          XEUtils.eachTree(this.shu_cha, n => {
            if (XEUtils.includes(val, n.id)) {
              xuan_ze.push(n.id)
            }
          }, {children: 'children'})
          this.setValue(xuan_ze)
        }, 500)

      }
      this.jian_ting_id_fang_dou(newVal)

    },
    //监听完整名称，自动填写 只兼容："'一级/二级'"
    jianTingAllName(newVal) {
      if (XEUtils.isUndefined(newVal)) return

      if (XEUtils.isUndefined(this.jianTingAllNameFangDou)) {
        this.jianTingAllNameFangDou = XEUtils.debounce(val => {

          val = XEUtils.toString(val).split(',')
          val = XEUtils.map(val, n => {
            n = XEUtils.toString(n).split('/')
            n = XEUtils.map(n, n3 => XEUtils.trim(n3))
            return n
          })

          let xuan_ze = []
          XEUtils.each(val, names => {
            XEUtils.eachTree(this.shu_cha, n => {
              if (XEUtils.last(names) === n.name) {
                xuan_ze.push(n.id)
              }
            }, {children: 'children'})
          })

          this.setValue(xuan_ze)
        }, 500)
      }
      this.jianTingAllNameFangDou(newVal)
    }
  },

  computed: {
    //获取选择的id,单选｜多选这里返回的都是数组
    getActiveIds() {
      let ids = this.activeIds
      if (!XEUtils.isArray(ids)) {
        ids = XEUtils.toString(ids).split(',')
      }
      // ids = XEUtils.map(ids, n => XEUtils.toInteger())

      return XEUtils.filter(ids, n => n)
    }
  }
}
</script>

<style scoped>

</style>
