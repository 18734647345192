<template>
  <nav-header title="专家库">
    <template #left>
      <a class="left-icon" href="" @click.prevent="onClickLeft">
        <van-icon name="arrow-left" size="18"/>
      </a>
    </template>
  </nav-header>
  <list-page @load="getLoad" ref="xiaLa" bottom="50px" top="50px">
    <div class="lun-bo">
      <div class="fo-dong">
        <div class="dao-hang">
          <zi-xun-header/>
        </div>
      </div>
      <div class="fo-dong-height"></div>
      <!--轮播-->
      <div class="special-swiper ">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(item,index) in swiperList" :key="index">
            <a :href="item.url" target="_blank">
              <van-image
                  width="100%"
                  height="auto"
                  :src="item.image"
              />
            </a>
          </van-swipe-item>
        </van-swipe>
      </div>
      <zhuan-jia-select ref="zhuanJiaSelect" @lol-submit="submit"/>
    </div>

    <div class="lie-biao prohibit-copy" oncontextmenu="return false;">
      <!-- 列表数据-->
      <div v-for="(item) in contentData" :key="item.id">
        <special-list :item="item"></special-list>
      </div>
    </div>

  </list-page>
  <footer-nav fixed/>
  <lian-xi-ke-fu ref="keFu"/>
  <div class="become-special-fixed" @click="$refs.keFu.show()">
    <i class="iconfont icon-zhuanjia" style="margin-right: 3px;"></i>
    我要成为专家
  </div>
</template>


<script>
//服务大厅
import NavHeader from '@/components/top/NavHeader'
import FooterNav from "../open/footerNav";
import http from "../../api/http";
import SpecialList from './module/SpecialList'
import XEUtils from "xe-utils";
import LianXiKeFu from "@/views/open/LianXiKeFu";
import ZiXunHeader from "@/views/platform/module/ZiXunHeader";
import ListPage from "@/components/list/ListPage";
import zhuanJiaSelect from "@/views/platform/module/zhuanJiaSelect"

export default {
  name: 'SpecialistLibrary',
  components: {
    zhuanJiaSelect,
    ListPage,
    ZiXunHeader,
    NavHeader,
    FooterNav,
    SpecialList,
    LianXiKeFu
  },
  data() {
    return {
      keyword: '',
      pageName: 'PlatformSpecialistLibrary',
      swiperList: [],//轮播
      specialList: [], // 专家分类
      specialList1: [], // 专家分类1
      specialList2: [], // 专家分类2
      isZheDie: true,//true折叠
      contentData: [],
      isShowMore: true,
      xiaLa: null,
      where: {},
    }
  },
  mounted() {
    this.getSwiper();
  },
  methods: {
    //是否返回
    onClickLeft() {
      let whereNumber = 0
      XEUtils.each(this.where, n => {
        if (!XEUtils.isEmpty(n)) whereNumber++
      })

      if (!whereNumber) {
        //执行返回
        this.$router.go(-1)
        return
      } else {
        //删除搜索
        this.where = XEUtils.clear(this.where, '')
        this.$refs.zhuanJiaSelect.reset()
        this.$refs.xiaLa.resetData()
      }
    },
    submit(where) {
      this.where = XEUtils.merge(this.where, where)
      this.$refs.xiaLa.resetData()
    },
    //轮播
    getSwiper() {
      http.get('/gzhphp/zhuan_jia_ku/getLunBo').then(res => {
        if (res.data) {
          res.data = res.data.map(n => {
            n.image = XEUtils.map(n.image, n => n.url)

            if (XEUtils.isArray(n.image)) {
              res.data.image = XEUtils.last(n.image)?.url
            }
            return n
          })
          this.swiperList = res.data;

        }
      })
    },

    //切换页面
    pageChange(page) {
      this.$router.push({name: page})
    },

    // 获取数据
    getLoad({num, size}) {
      let where = XEUtils.clone(this.where)
      where.page = num
      where.data_length = size
      if (num == 1) this.contentData = []

      http.get('/gzhphp/zhuan_jia_ku/getList', {
        params: where,
        load: false,
      }).then(res => {
        if (res.code != 1) return

        XEUtils.each(res.data.data, n => {
          this.contentData.push(n)
        })
        this.$refs.xiaLa.endSuccess(res.data.data.length)
      });
    },

  },
  beforeRouteEnter(to, from, next) { // 如果没有配置顶部按钮或isBounce,则beforeRouteEnter不用写
    next(vm => {
      // 滚动到原来的列表位置,恢复顶部按钮和isBounce的配置
      vm.$refs.xiaLa && vm.$refs.xiaLa.beforeRouteEnter()
    })
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.xiaLa && this.$refs.xiaLa.beforeRouteLeave()
    next()
  },
}
</script>

<style scoped lang="less">
.page-content {
  display: block;

  .dao-hang {
    height: auto;
  }
}

.select_button {
  width: 90%;
  border-radius: 2px;
  text-align: center;
  margin-bottom: 5px;

  background-color: #c8c9cc;
  color: white;
  border: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}

.my-swipe .van-swipe-item {
  color: #fff;
}

.btn_active {
  color: white;
  background-color: #4A4096;
  font-size: 13px;
}

.icon-xiangxiazhanhang {
  display: inline-block;
  font-size: 12px;
}

.icon-xiangxiazhanhang.more {
  transform: rotate(90deg);
}

.icon-xiangxiazhanhang.show_down {
  transform: rotate(-90deg);
}

.body {
  position: relative;
}

/* 浮动布局*/
.become-special-fixed {
  background-color: #ef9135;
  position: fixed;
  right: 0;
  bottom: 20%;
  padding: 5px;
  border-radius: 8px 0 0 8px;
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.fo-dong {
  position: fixed;
  top: 46px;
  width: 100%;
  z-index: 10;
}

.fo-dong-height {
  height: 44px;
}
</style>
