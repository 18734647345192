<template>
  <van-search
      v-model="getKeyword"
      show-action
      placeholder="请输入搜索关键词"
      readonly
      @click="status = true"
  >
    <template #action>
      <div @click="status = true">搜索</div>
    </template>
  </van-search>
  <van-popup
      v-model:show="status"
      position="bottom"
      teleport="body"
      style="width: 100%;height: 200px;"
  >
    <div class="tal-flex tal-flex-col tal-w-full tal-h-full">
      <div class="tal-flex-1">
        <van-form>
          <van-cell-group>
            <van-field
                v-model="where.keyword"
                label="关键词"
                placeholder="请输入搜索关键词"
            />
            <select-er-ji-type
                url="/portal/index/get_zhuan_jia_type"
                label="所属行业"
                v-model:id="where.type_id"
                v-model:name="type_name"
                placeholder="请选择所属行业"
            />
            <duo-xuan-address label="所在地区" v-model:id="where.address_ids" data-type="jian_duan_er_ji_di_qu" />
          </van-cell-group>
        </van-form>
      </div>
      <div class="tal-grid tal-grid-cols-2">
        <div class="">
          <van-button block @click="reset">重置</van-button>
        </div>
        <div class="">
          <van-button block type="primary" @click="submit">搜索</van-button>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import SelectErJiType from "@/components/select/SelectErJiType";
import XEUtils from "xe-utils";
import DuoXuanAddress from "@/components/select/DuoXuanAddress";

export default {
  name: "zhuanJiaSelect",
  components: {DuoXuanAddress, SelectErJiType},
  data() {
    return {
      type_name:'',
      where: {
        address_ids:'',
        keyword: '',
        type_id: ''
      },
      status: false
    }
  },
  methods: {
    reset() {
      XEUtils.clear(this.where, '')
    },
    submit() {
      this.status = false
      this.$emit('lol-submit', this.where)
    },

  },
  computed: {
    getKeyword() {
      let names = []
      if (!XEUtils.isEmpty(XEUtils.toString(this.where.keyword))) {
        names.push(this.where.keyword)
      }

      if (!XEUtils.isEmpty(XEUtils.toString(this.where.type_id))) {
        names.push(XEUtils.toValueString(this.type_name).split(',').join('、'))
      }
      return names.join('、')

    }
  }
}
</script>

<style scoped>

</style>
